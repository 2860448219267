<template>
  <div id="app" data-app>
    <BlockScreen v-if="showBlockScreen" />
    <template v-else>
      <HeaderBar @login="openLogin" @logout="logout" @register="toRegister" @password="toRecovery" />
      <div class="main">
        <router-view @toRegister="toRegister" @toRoleRegister="toRoleRegister" />
      </div>
      <FooterBar @openFeedback="showFeedback = true" />
      <LoginForm v-if="showModal && modalType === 'login'" @hideModal="hideModal" @toRecovery="toRecovery" @toRegister="toRegister" />
      <PasswordForm
        v-if="showModal && modalType === 'recovery'"
        :checkword="checkword"
        :email="email"
        @hideModal="hideModal"
        @toLogin="toLogin"
        @showSuccess="openSuccess"
      />
      <RegisterForm
        v-if="showModal && modalType === 'register'"
        :inviteInfo="inviteInfo"
        :referralInfo="referralInfo"
        :inviteInfoID="inviteInfoID"
        :showBoth="showBoth"
        :role="registerRole"
        @hideModal="hideModal"
        @toActivation="toActivation"
      />
      <ModalFeedback v-if="showFeedback" @hideModal="hideFeedback" @showSuccess="showSuccessFeedback = true" />
      <ModalWarning
        v-if="showWarning"
        title="Уведомление"
        text="Для завершения регистрации необходимо осуществить вход по логину и паролю, направленным на Ваш e-mail, и подтвердить правила программы. Только после этого Вам будут начисляться баллы."
        @hideModal="showWarning = false"
      />
      <ModalSuccess v-if="showSuccess" text="Пароль направлен на указанный адрес электронной почты" @hideModal="showSuccess = false" />
      <ModalSuccess
        v-if="showSuccessFeedback"
        text="Спасибо.<br/> Письмо отправлено.<br/> В ближайшее время наши менеджеры ответят вам."
        @hideModal="showSuccessFeedback = false"
      />
      <ActivationModal v-if="showActivation" :form="registerForm" @hideModal="showActivation = false" @openWarning="showWarning = true" />
      <TermsModal v-if="showTerms" @hideTerms="hideTerms" />
      <div class="preloader" v-if="preloader > 0">
        <PreloaderIcon />
      </div>
      <CookieModal v-if="showCookies" @acceptCookies="acceptCookies" />
    </template>
  </div>
</template>

<script>
import HeaderBar from "@/components/template/HeaderBar.vue";
import FooterBar from "@/components/template/FooterBar.vue";
import LoginForm from "@/components/template/LoginForm.vue";
import PasswordForm from "@/components/template/PasswordForm.vue";
import RegisterForm from "@/components/template/RegisterForm.vue";
import ModalFeedback from "@/components/template/ModalFeedback.vue";
import TermsModal from "@/components/template/TermsModal.vue";
import PreloaderIcon from "@/components/template/PreloaderIcon.vue";
import ModalWarning from "@/components/template/ModalWarning.vue";
import ModalSuccess from "@/components/template/ModalSuccess.vue";
import ActivationModal from "@/components/template/ActivationModal.vue";
import CookieModal from "@/components/template/CookieModal.vue";
import BlockScreen from "@/views/Public/BlockScreen.vue";

export default {
  name: "App",
  components: {
    HeaderBar,
    FooterBar,
    LoginForm,
    PasswordForm,
    RegisterForm,
    ModalFeedback,
    TermsModal,
    PreloaderIcon,
    ModalWarning,
    ModalSuccess,
    ActivationModal,
    CookieModal,
    BlockScreen,
  },
  data() {
    return {
      showModal: false,
      showFeedback: false,
      showTerms: false,
      showWarning: false,
      showSuccess: false,
      showSuccessFeedback: false,
      showActivation: false,
      modalType: "login",
      title: 'Программа лояльности "Старательная лига"',
      inviteInfo: null,
      inviteInfoID: null,
      referralInfo: null,
      checkword: null,
      email: null,
      showBoth: false,
      registerRole: null,
      registerForm: null,
      showCookies: true,
    };
  },
  created() {
    this.init();
    this.loadRecaptchaScript();
  },
  async mounted() {
    this.showCookies = !sessionStorage.cookies_accepted;
    if (window.location.href.indexOf("invite") > -1) {
      let url = new URL(window.location.href);
      this.inviteInfoID = url.searchParams.get("invite");
      if (!this.isAuth) {
        let res = await this.$api.invite.getInviteInfo(this.inviteInfoID);
        this.inviteInfo = res.result;
        this.showBoth = false;
        this.registerRole = this.inviteInfo.target_promo_group_system_name;
        this.modalType = "register";
        this.showModal = true;
      }
    } else if (window.location.href.indexOf("referral") > -1) {
      let url = new URL(window.location.href);
      let id = url.searchParams.get("referral");
      let role = url.searchParams.get("role");
      if (!this.isAuth) {
        let res = await this.$api.invite.getReferralInfo(id);
        this.referralInfo = res.result;
        this.showBoth = false;
        this.registerRole = role;
        this.modalType = "register";
        this.showModal = true;
      }
    } else if (window.location.href.indexOf("check_word") > -1 && window.location.href.indexOf("email") > -1) {
      let url = new URL(window.location.href);
      this.checkword = url.searchParams.get("check_word");
      this.email = url.searchParams.get("email");
      this.modalType = "recovery";
      this.showModal = true;
    }
  },
  watch: {
    openTerms: function () {
      if (this.openTerms === true) {
        this.showTerms = true;
      }
    },
  },
  computed: {
    preloader() {
      return this.$store.getters.GET_PRELOADER;
    },
    isAuth() {
      return this.$store.getters["user/GET_IS_AUTH"];
    },
    openTerms() {
      return this.$store.getters["user/GET_SHOW_TERMS"];
    },
    userRole() {
      return JSON.parse(localStorage.getItem("userRole"));
    },
    userProfile() {
      return this.$store.getters["user/GET_CURRENT_USER"];
    },
    showBlockScreen() {
      const setDate = new Date("01/01/2025");
      if (new Date().getTime() >= new Date(setDate).getTime()) return true;
      return false;
    },
  },
  methods: {
    init() {
      document.title = this.title;
      this.$store.dispatch("initMainPage");
      this.scrollTop();
    },
    scrollTop() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    async logout() {
      await this.$store.dispatch("user/logout");
    },
    openLogin() {
      this.modalType = "login";
      this.showModal = true;
    },
    toRecovery() {
      this.$store.dispatch("user/resetError");
      this.modalType = "recovery";
      this.showModal = true;
    },
    toLogin() {
      this.$store.dispatch("user/resetError");
      this.modalType = "login";
      this.showModal = true;
    },
    toRegister() {
      this.$store.dispatch("user/resetError");
      this.showBoth = true;
      this.registerRole = null;
      this.modalType = "register";
      this.showModal = true;
    },
    toRoleRegister(role) {
      this.$store.dispatch("user/resetError");
      this.showBoth = false;
      this.registerRole = role;
      this.modalType = "register";
      this.showModal = true;
    },
    toActivation(payload) {
      this.hideModal();
      this.registerForm = payload;
      this.registerRole = payload.user_role;
      this.showActivation = true;
    },
    hideModal() {
      this.showModal = false;
      this.$store.dispatch("user/resetError");
    },
    hideFeedback() {
      this.showFeedback = false;
    },
    hideTerms() {
      this.showTerms = this.showActivation = false;
    },
    openSuccess() {
      this.showModal = false;
      this.showSuccess = true;
    },
    acceptCookies() {
      sessionStorage.setItem("cookies_accepted", true);
      this.showCookies = false;
    },
    loadRecaptchaScript() {
      let script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.src = "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit";
      document.head.append(script);
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "FFGoodPro", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background-color: $color-grey-10;
}

.main {
  padding-top: 86px;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  background-color: #ffffff99;
  animation: preloader 0.5s ease forwards;
}
</style>
